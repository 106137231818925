<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="right"
      :model="form.data"
      :rules="form.rules"
    >
      <el-form-item prop="name" label="姓名：">
        <el-input
          v-model="form.data.name"
          clearable
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="account" label="账号：">
        <el-input
          v-model="form.data.account"
          maxlength="11"
          clearable
          :disabled="isChange"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="isChange ? false : true" :prop="isChange ? '11' : 'password'" label="密码：">
        <el-input
          v-model="form.data.password"
          clearable
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <!-- 总管理端的负责人如果是负责某个区县的，这个账号在配置其他账号的时候没有所属地区的选择(只有市级账号才有) -->
      <template  v-if="cur_area == 0">
        <el-form-item label="所属地区：" prop="area">
          <el-cascader
            style="width:100%;"
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: true, emitPath: false }">
          </el-cascader>
        </el-form-item>
        <el-form-item label="角色：" prop="is_admin">
          <el-radio-group v-model="form.data.is_admin">
            <el-radio :label="1">管理员</el-radio>
            <el-radio :label="2">展示账号(仅展示工作台及使用指南)</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import MyVueCroppers from "@/components/MyVueCroppers.vue";
  import { getStorage } from '@/storage/index.js'
  export default {
    name: 'AccountEdit',
    props:['powerArr'],
    components: {
      MyVueCroppers,
    },
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            account: '',
            password: '',
            area: '',
            is_admin: 1, // 是否是管理员 1:是 2:否
          },
          rules: {
            name: [{required: true, message: "请输入姓名！", trigger: "change"}],
            account: [{required: true, message: "请输入账号！", trigger: "change"}],
            password: [{required: true, message: "请输入密码！", trigger: "change"}],
          }
        },
        areaArr: [],
        cur_city: getStorage("city"),
        cur_area: getStorage("area"),
      }
    },
    created() {
      this.getArea();
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.$http.get('/government/account/info',{params:{id:row.id}}).then(res => {
          if(res.code === 1) {
            // this.form.data = res.data;

            Object.keys(res.data).forEach(item => {
              this.form.data[item] = res.data[item]
            })
            this.form.data.is_admin = res.data.role_type == 5 ? 2 : 1;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = !this.isChange ? '/government/account/add' : '/government/account/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
      // 根据市id查询区县
      getArea() {
        this.$http.post('/common/district/all',{pid: this.cur_city}).then(res => {
          if(res.code == 1) {
            this.areaArr = res.data.list;
          }
        })
      },
    }
  }
</script>
