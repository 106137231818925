<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="姓名" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话号码" prop="account">
          <el-input clearable v-model="table.params.account" placeholder="请输入电话号码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:province="{row}">
        <span>{{row.province_str}}{{row.city_str}}{{row.area_str}}</span>
        <span></span>
      </template>
      <template v-slot:role_type="{row}">
        <span>{{row.role_type == 5 ? '展示账号' : '管理员'}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click='showPass(row)'>修改密码</el-button>
        <el-button type="text" icon="el-icon-edit" @click='showEdit(row)'>修改</el-button>
        <el-button type="text" icon="el-icon-delete"  @click='remove(row)'>删除</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
    <password ref="pass"></password>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Password from './components/Password.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'AccountList',
  components: {
    VTable,
    Edit,
    Password
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "姓名", hidden: false },
        { name: "account", label: "账号", hidden: false},
        { name: "role_type", label: "角色", hidden: false},
        { name: "province", label: "所属地区", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "action", label: "操作", width: "220", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          account: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/government/account/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    showPass(row) {
      let dom = this.$refs.pass
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/government/account/delete', {id: row.id}).then(res => { 
              if(res.code === 1) {
                this.$message.success('操作成功！');
                this.getTable();
              } else {
                this.$message.error(res.msg)
              }
            })
            done()
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>